import { useEffect, useState } from "react";
import { AppBar, Tab, Tabs } from "@mui/material";
import _ from "lodash";

import './styles.scss';

const TabPanel = (props: any): React.JSX.Element => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            display={'flex'}
            flexDirection={'column'}
            flex={'1'}
            {...other}>
            {value === index && (
                <div className="tab-content-box" >
                    {children}
                </div>
            )}
        </div>
    );
};

`TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };`

const a11yProps = (index: number) => {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
};

const TablePopupTabs = (pageContext: any): React.JSX.Element => {
    const position = _.findIndex(pageContext.tabData, (tab: { selected?: number | boolean }): boolean => {
        return tab.selected === true;
    });

    const [value, setValue] = useState(0);

    useEffect((): void => {
        setValue(position !== -1 ? position : 0);
    }, [position]);

    const handleChange = (event: any, newValue: number): void => {
        setValue(newValue);
    };

    return (
        <div id="tablePopupTabs">
            <AppBar elevation={0} position="static" className="app-bar">
                <Tabs
                    TabIndicatorProps={{
                        style: {
                            borderBottom: '2px solid #1570EF'
                        }

                    }}
                    value={value}
                    className="navigation-tabs"
                    onChange={handleChange}
                    indicatorColor="secondary"
                    variant="fullWidth"
                    textColor="inherit"
                    aria-label="full width tabs example">
                    {
                        pageContext.tabData.map((item: any, key: number): React.JSX.Element => {
                            return (
                                <Tab className="navigation-tab-button" key={key} disabled={item?.disabled} disableRipple label={item.title} {...a11yProps(key)}></Tab>
                            );
                        })
                    }
                </Tabs>
            </AppBar>
            <div className="tabContentContainer">
                {
                    pageContext.tabData.map((item: any, key: any): React.JSX.Element => {
                        return (
                            <TabPanel value={value} index={key} key={key} >{item.data}</TabPanel>
                        );
                    })
                }
            </div>
        </div>
    );
};

export default TablePopupTabs;