import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './styles.scss';
import toast from 'react-hot-toast';
import MuiChip from '../FormComponents/MuiChip';
import _ from 'lodash';
import TablePopupTabs from './TablePopupTabs';

interface TablePopupTabs {
    title: string;
    data: React.ReactNode;
}

interface TablePopupDetailsProps {
    onPopupClose: () => void;
    tabData: TablePopupTabs[];
    imageSrc?: string;
    altImage?: string;
    title: string;
    subTitle?: string;
    email?: string;
    icp?: string[];
    buyerPersona?: string[];
}

const TablePopupDetails: React.FC<TablePopupDetailsProps> = (props): React.JSX.Element =>
{

    const { onPopupClose, tabData, imageSrc, altImage, title, subTitle, email, icp, buyerPersona } = props;

    return (
        <div id="tablePopupDetails">
            <div className="table-popup-details-container">
                <div className="header-container">
                    <div className="user-basic-info">
                        {
                            imageSrc && imageSrc !== null && imageSrc !== '' ? 
                            <img 
                                src={imageSrc}
                                className="user-logo"
                                alt="user-company-logo"
                            />
                            :
                            <div className="user-logo">
                                {altImage}
                            </div>
                        }
                        <div className="user-details">
                            <h3 className="user-name">{title}</h3>
                            {subTitle && subTitle !== '' && <h3 className="user-company">{subTitle}</h3>}
                            {email && 
                                <div className="user-email">
                                    {email}
                                    <FontAwesomeIcon cursor={'pointer'} onClick={() => {
                                        navigator.clipboard.writeText(email);
                                        toast.success('Email copied to clipboard');
                                    }} icon={['fal', 'copy']} />
                                </div>
                            }
                            {(icp && icp?.length > 0) || (buyerPersona && buyerPersona?.length > 0) && <div className="icp-bp-container">
                                {icp && icp?.length > 0 && <MuiChip circleIcon label={icp?.toString()} chipColor='blue' />}
                                {buyerPersona && buyerPersona?.length > 0 && <MuiChip circleIcon label={buyerPersona?.toString()} chipColor='red' />}
                            </div>}
                        </div>
                    </div>
                    <FontAwesomeIcon cursor={'pointer'} icon={['fal', 'xmark']} onClick={onPopupClose} />
                </div>
                <div className="popup-details-tabs-wrapper">
                    <TablePopupTabs tabData={tabData} />
                </div>
            </div>
        </div>
    );
};

export default TablePopupDetails;
