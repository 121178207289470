import React, { useEffect } from 'react';
import 'react-pro-sidebar/dist/css/styles.css';
import './styles.scss';
import { LocalStorage } from '../scripts/LocalStorage';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import SideDrawer from '../common/SideDrawer';
import PopUp from '../common/PopUp';
import CustomDialog from '../common/CustomDialog';
import { useDispatch } from 'react-redux';
import { getCsrfToken } from '../scripts/helpers';
import { csrfTokenValue } from '../redux/authToken';
import DemoAppBar from '../common/DemoAppBar';
import { Toaster } from 'react-hot-toast';
import { clarity } from 'react-microsoft-clarity';
import SideNavBar from '../common/SideNavBar';
import { EventModules, SettingsModules, SideBarModules } from '../interfaces/sideNavbar/side-nav-bar_interface';

interface ProtectedRouteProps { 
    module: SideBarModules;
    subModule?: SettingsModules | EventModules;
    sidebar?: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = (props):React.JSX.Element => 
{

    const dispatch = useDispatch();

    const user = LocalStorage.get('@UserMe');
    // JSON.stringify(user))
    const location = useLocation();

    if(import.meta.env.VITE_NODE_ENV !== 'local' && user && user.orgId)
    {
        const claritySet = LocalStorage.get('@Organization');
        if(!claritySet)
        {
            clarity.init(import.meta.env.VITE_CLARITY_ID);
            clarity.identify(user.id, { userProperty: user.email, organization: user.orgId });
            clarity.setTag('organization', user.orgId);
            LocalStorage.set('@Organization', user.orgId);
        }
    }


    const fetchCsrfTokenData = async ():Promise<void> => 
    {
        const csrf = await getCsrfToken();
        if (csrf) 
        {
            dispatch(csrfTokenValue({
                csrfToken: csrf.csrfToken 
            }));
        }
    };

    // const userBasedClarity = ():void =>
    // {
    //     try 
    //     {
    //         if (clarity.hasStarted() && user) 
    //         {
    //             clarity.identify(user.id, { userProperty: user.email });
    //         }
    //     } 
    //     catch (error) 
    //     {
            
    //     }

    // };

    useEffect(():void => 
    {
        fetchCsrfTokenData();
        // userBasedClarity();
    }, []);  

    const component = <Box sx={{
        width: '100%', backgroundColor: '#FFFFFF', height: '100%' 
    }}>
        <Box className='w-100 h-100' sx={{
            display: 'flex', flexDirection: 'column' 
        }}>
            <Box sx={{
                width: '100%',
                height: '100%'
            }}><Outlet /></Box>
        </Box>
    </Box>;

    const components =
        <>
            <SideNavBar selectedComponent={props?.module} subModule={props?.subModule} mainContent={component} showDrawer={props?.sidebar as boolean} />
            {import.meta.env.VITE_NODE_ENV === 'demo' && <DemoAppBar />}
            <SideDrawer />
            <CustomDialog />
            <PopUp />
            <Toaster />
        </>

    return (
        user ? (
            components
            // import.meta.env.VITE_NODE_ENV === 'local' ? components : import.meta.env.VITE_NODE_ENV !== 'production' ? <CmdKConfigurer token={APP_CONSTANTS.CMDK_TOKEN}>{components}</CmdKConfigurer> : components
        ) : <Navigate to="/login" state={{
            from: location
        }} replace />
    );
};

export default ProtectedRoute;
