import { ActivityLog } from "../../interfaces/activity-log_interface";
import { EventRegistrantStatus } from "../../pages/Events/enum/event-registrant.enum";
import { CONTENT } from "../i18n";
import moment from "moment";
import { FynoResponseStatus } from "../../enums/activity-log.enum";
import { ActivityLogData } from "../../common/ActivityLog/interface/activity-log-component_interface";

export const eventRegistrantActivityLog = (activityLog: ActivityLog[]) =>
{
    const activityLogArr: ActivityLogData[] = [];

    for(const data of activityLog)
    {
        if(data?.changeLog?.length === 0) { 
            activityLogArr.push({ 
                title: `Registered`, 
                icon: 'file',
                modifiedTime: moment.unix(Number(data.timestamp)).format('DD MMMM, HH:MM:ss'),
            });
        }
        data?.changeLog?.forEach((log) => {
            switch(log?.key) {
                case 'status':
                    activityLogArr.push({
                        title: `Status Changed: ${CONTENT.EVENTS_MODULE.REGISTRANTS.STATUS[log?.previousValue as EventRegistrantStatus]} -> ${CONTENT.EVENTS_MODULE.REGISTRANTS.STATUS[log?.currentValue as EventRegistrantStatus]}`,
                        icon: 'arrow-right-from-line',
                        modifiedTime: moment.unix(Number(data.timestamp)).format('DD MMMM, HH:MM:ss'),
                    });
                    break;
                case 'emailNotification': 
                    activityLogArr.push({
                        title: `${log?.status === FynoResponseStatus.DELIVERED ? `${CONTENT.EVENTS_MODULE.REGISTRANTS.FYNO_RESPONSE_EMAIL_STATUS[log?.currentValue as keyof typeof CONTENT.EVENTS_MODULE.REGISTRANTS.FYNO_RESPONSE_EMAIL_STATUS]} email sent` : `${CONTENT.EVENTS_MODULE.REGISTRANTS.FYNO_RESPONSE_EMAIL_STATUS[log?.currentValue as keyof typeof CONTENT.EVENTS_MODULE.REGISTRANTS.FYNO_RESPONSE_EMAIL_STATUS]} email failed to send`}`,
                        icon: 'envelope',
                        modifiedTime: moment.unix(Number(data.timestamp)).format('DD MMMM, HH:MM:ss'),
                    });
                    break;
                case 'firstName': 
                    activityLogArr.push({
                        title: `First Name Changed: ${log?.previousValue} -> ${log?.currentValue}`,
                        icon: 'arrow-right-from-line',
                        modifiedTime: moment.unix(Number(data.timestamp)).format('DD MMMM, HH:MM:ss'),
                    });
                    break;
                case 'lastName':
                    activityLogArr.push({
                        title: `Last Name Changed: ${log?.previousValue} -> ${log?.currentValue}`,
                        icon: 'arrow-right-from-line',
                        modifiedTime: moment.unix(Number(data.timestamp)).format('DD MMMM, HH:MM:ss'),
                    });
                    break;
                case 'notes':
                    activityLogArr.push({
                        title: `Added notes: ${log?.currentValue}`,
                        icon: 'notes',
                        modifiedTime: moment.unix(Number(data.timestamp)).format('DD MMMM, HH:MM:ss'),
                    });
                    break;
                // case 'additionalData':
                //     const previousData = log?.previousValue as Record<string, any>;
                //     const currentData = log?.currentValue as Record<string, any>;
                //     const changes: string[] = [];

                //     for (const key in previousData) {
                //         if (previousData[key] !== currentData[key]) {
                //             changes.push(`${key} Changed: ${previousData[key]} -> ${currentData[key]}`);
                //         }
                //     }

                //     activityLogArr.push({
                //         title: changes.join(', '),
                //         icon: 'arrow-right-from-line',
                //         modifiedTime: moment.unix(Number(data.timestamp)).format('DD MMMM, HH:MM:ss'),
                //     });
            }
        })
    }

    return activityLogArr?.reverse();
};