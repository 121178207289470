import React, { useState, useRef, useEffect, MouseEvent } from 'react';

import './styles.scss';

// Menu Component
interface MenuProps {
    children: React.ReactNode;
    menuButton: React.ReactNode;
}

const Menu: React.FC<MenuProps> = ({ children, menuButton }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [open, setOpen] = useState(false);
    const menuRef = useRef<HTMLDivElement | null>(null);

    // Toggle the menu open/close
    const handleClick = (event: MouseEvent<HTMLElement>) => {
        if (open) {
            setOpen(false); // Close the menu if it's already open
        } else {
            setAnchorEl(event.currentTarget);
            setOpen(true); // Open the menu when avatar is clicked
        }
    };

    // Close the menu if clicked outside
    const handleClickOutside = (event: MouseEvent) => {
        if (menuRef.current && !menuRef.current.contains(event.target as Node) && !anchorEl?.contains(event.target as Node)) {
            setOpen(false);
        }
    };

    // Add event listener for clicking outside
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [anchorEl]);

    // Dynamic position for the menu based on space available
    const getMenuPosition = () => {
        if (!anchorEl) return { top: '40px', left: '0' }; // Default position

        const rect = anchorEl.getBoundingClientRect();
        const menuHeight = 200; // Approximate height of the menu
        const menuWidth = 240; // Approximate width of the menu

        // Try to open the menu below the avatar
        let top = rect.bottom + window.scrollY;
        let left = rect.left + window.scrollX;

        // If there's not enough space below, open it above
        if (top + menuHeight > window.innerHeight) {
            top = rect.top - menuHeight + window.scrollY;
        }

        // If the menu goes off the right edge, position it to the left
        if (left + menuWidth > window.innerWidth) {
            left = window.innerWidth - menuWidth - 10; // 10px for some margin
        }

        return { top: `${top}px`, left: `${left}px` };
    };

    const menuPosition = getMenuPosition();

    return (
        <div id="menuComponent">
            <div className="menu-wrapper">
                {/* Account Icon Button */}
                <button
                    onClick={handleClick}
                    className="icon-button"
                    aria-controls={open ? 'menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    {menuButton}
                </button>

                {/* Account Menu */}
                <div
                    ref={menuRef}
                    className={`menu ${open ? 'open' : ''}`}
                    id="menu"
                    style={{ top: menuPosition.top, left: menuPosition.left }}
                    onClick={handleClick}
                >
                    {/* Custom children content can be passed to the menu */}
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Menu;
