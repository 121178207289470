import ActivityLogComponent from "../../../../common/ActivityLog/ActivityLogComponent";
import { ActivityLog } from "../../../../interfaces/activity-log_interface";
import { EventRegistrant } from "../../../../pages/Events/interfaces/event-registrant_interface";
import { eventRegistrantActivityLog } from "../../../../scripts/activityLogGenerator/eventRegistrant.activityLog";

interface RegistrantActivityLogProps {
    registrantData: EventRegistrant;
}

const RegistrantActivityLog: React.FC<RegistrantActivityLogProps> = (props): React.JSX.Element =>
{
    const { registrantData } = props;

    return (
        <div>
            {registrantData?.activityLog ?
                <ActivityLogComponent
                    activityLog={eventRegistrantActivityLog(registrantData?.activityLog as ActivityLog[])}
                    componentHeight={'calc(100vh - 446px)'}
                />
                :
                <div className="registrant-log-empty">
                    No activity log for this registrant
                </div>
            }
        </div>
    );
};

export default RegistrantActivityLog;