import { useContext, useEffect, useMemo, useState } from "react";
import { CustomButton } from "../../../../../common/FormComponents/Buttons";
import DataImport from "../../../../../common/DataImport";
import APP_CONSTANTS from "../../../../../scripts/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar } from "@mui/material";
import EventRegistrantGraph from "./EventRegistrantGraph";
import TableEmptyComponent from "../../../../../common/TableEmptyComponent";
import TanstackTable from "../../../../../common/TanstackTable/TanstackTable";
import { EventRegistrant } from "../../../interfaces/event-registrant_interface";
import { registrantFilterColumnOptions, registrantInitialColumnData, useRegistrantColumnHelper } from "./registrantColumn.helper";
import { useTablePagination } from "../../../../../contexts/TablePaginationContext";
import { bulkUpdateRegistrantStatus, deleteEventRegistrant, downloadInvoices, downloadRegistrantCSV, getAllEventRegistrants, getEventRegistrantCount, getEventRegistrantStatusCount, getRegistrantsGraphData } from "../../../../../scripts/apis/eventRegistrants";
import { EventRegistrantStatus } from "../../../enum/event-registrant.enum";
import _, { camelCase } from "lodash";
import { EventGraphObject, EventTicket } from "../../../interfaces";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { IEventReduxValues, IEventsDispatch } from "../../../../../components/Events/interfaces/create-events_interface";
import { getAlleventTickets, ticketsCount } from "../../../../../scripts/apis/eventTickets";
import { saveAs } from 'file-saver';
import { EnableInvoiceStatus, EnableTicketWaitlistStatus, TicketType } from "../../../enum";
import toast from "react-hot-toast";
import { getRegistrationFormById } from "../../../../../scripts/apis/registrationForm";
import { CustomFieldMandatory } from "../../../../Registration/enum/custom-field.enum";
import RegistrantDetails from "./RegistrantDetails";
import eventBus from "../../../../../scripts/event-bus";
import DeletePopup from "../../../../../common/DeletePopup";
import { useRegistrantToolbarTabs } from "../../../../../hooks/registrantToolbarTabs";
import TanstackToolbarWithServerFilter from "../../../../../common/TanstackTable/TanstackToolbarWithServerFilter";
import { UserContext } from "../../../../../contexts/UserContext";
import { UserViewType } from "../../../../Settings/enum/users.enum";
import { createColumnHelper } from "@tanstack/react-table";
import { buildInitialsForPicture, getEncodedParams } from "../../../../../scripts/helpers";
import MuiChip from "../../../../../common/FormComponents/MuiChip";
import { MinimalViewComponent } from "../../../../../common/MinimalView/MinimalViewComponent";
import PopupDialog from "../../../../../common/PopupDialog";
import RegistrantUpdateComponent from "../../../../../components/Events/EventRegistrants/RegistrantUpdateComponent";
import { TableActions } from "../../../../../common/TableActions";
import ColumnVisibilityDropdown from "../../../../../common/TanstackTable/ColumnVisibilityDropdown";
import registrantEmptyImage from '../../../../../assets/icons/empty-state/registrantsEmpty.svg';
import CustomTooltip from "../../../../../common/Tooltip";
import TablePopup from "../../../../../common/TablePopup/TablePopup";
import TablePopupDetails from "../../../../../common/TablePopup/TablePopupDetails";
import RegistrantStatus from "../../../../../components/Events/EventRegistrants/RegistrantDetails/RegistrantStatus";

import './styles.scss';
import RegistrantDetailedInfo from "../../../../../components/Events/EventRegistrants/RegistrantDetails/RegistrantDetailedInfo";
import RegistrantActivityLog from "../../../../../components/Events/EventRegistrants/RegistrantDetails/RegistrantActivityLog";

interface EventRegistrantsPageProps {
    eventId: string;
}

const EventRegistrantsPage: React.FC<EventRegistrantsPageProps> = (props): React.JSX.Element => {


    const {
        userDetails
    } = useContext(UserContext);

    const { eventId } = props;
    const searchParams = new URLSearchParams();
    const status = new URLSearchParams(window.location?.search)?.get('status');
    const navigate = useNavigate();
    const currentpath = useLocation().pathname;

    const { pageSize, currentPage, updateCurrentPage, updatePageSize } = useTablePagination();

    const eventReduxData = useSelector((state: IEventsDispatch): IEventReduxValues => {
        return state.events.value;
    });

    const csvoptions = {
        sample_template_url: `${APP_CONSTANTS.API_DOMAIN}${APP_CONSTANTS.API_V1}/event-registrant/${eventId}/generate-csv`,
        sample_template_button_text: 'Registrants Template'
    };

    const [chartVisibility, setChartVisibility] = useState<boolean>(false);
    const [isEmpty, setIsEmpty] = useState<boolean>(false);
    const [dynamicColumns, setDynamicColumns] = useState<any>([]);
    const [registrantCount, setRegistrantCount] = useState<number>(0);
    const [totalRegistrantCount, setTotalRegistrantCount] = useState<number>(0);
    const [csvBoxReady, setCsvBoxReady] = useState<boolean>(false);
    const [rows, setRows] = useState<EventRegistrant[]>([]);
    const [showSpinner, setShowSpinner] = useState<boolean>(true);
    const [registrantStatusCount, setRegistrantStatusCount] = useState<EventGraphObject>();
    const [refresh, setRefresh] = useState<boolean>(false);
    const [tickets, setTickets] = useState<EventTicket[]>([]);
    const [invoiceDownloadSpinner, setInvoiceDownloadSpinner] = useState<boolean>(false);
    const [isEnableInvoiceDownload, setIsEnableInvoiceDownload] = useState<boolean>(false);
    const [csvConversionSpinner, setCsvConversionSpinner] = useState<boolean>(false);
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
    const [selectedRegistrant, setSelectedRegistrant] = useState<EventRegistrant | null>(null);
    const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
    const [currentUrl, setCurrentUrl] = useState(window.location.href);
    const [showBulkActionsPopup, setShowBulkActionsPopup] = useState<boolean>(false);
    const [bulkUpdateSpinner, setBulkUpdateSpinner] = useState<boolean>(false);
    const [showRegistrantDetailsPopup, setShowRegistrantDetailsPopup] = useState<boolean>(false);
    const [refreshCounts, setRefreshCounts] = useState<boolean>(false);
    const [trendData, setTrendData] = useState<EventGraphObject>();
    const [drawerOpen, setDrawerOpen] = useState(false);

    const tablePopupTabData = 
    [
        {
            title: 'Status',
            data: <RegistrantStatus setSelectedRegistrant={setSelectedRegistrant} eventId={eventId} registrantData={selectedRegistrant as EventRegistrant} setRefreshCounts={setRefreshCounts} statusCount={registrantStatusCount as EventGraphObject} setRegistrants={setRows} />
        },
        {
            title: 'Details',
            data: <RegistrantDetailedInfo setSelectedRegistrant={setSelectedRegistrant} eventId={eventId} registrantData={selectedRegistrant as EventRegistrant} setRegistrants={setRows}  />
        },
        {
            title: 'Activity',
            data: <RegistrantActivityLog registrantData={selectedRegistrant as EventRegistrant} />
        }
    ];

    const openDrawer = (data: EventRegistrant): void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: `${data?.firstName ?? ''} ${data?.lastName || data?.additionalData?.lastName || ''}`,
            hideCloseButton: true,
            component: <RegistrantDetails statusCount={registrantStatusCount} registrant={data} eventId={eventId} setRefresh={setRefresh} />,
            noSidebarPadding: true
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
        });
    };

    const onRowDelete = (data: EventRegistrant): void => 
    {
        setShowDeletePopup(true);
        setSelectedRegistrant(data);
    };

    const columns = useRegistrantColumnHelper(registrantInitialColumnData, eventReduxData, tickets, rows, openDrawer, onRowDelete);

    const columnHelper = createColumnHelper<EventRegistrant>();
    const minimalColumns = [
        columnHelper.accessor('companyLogo' as any, {
            cell: (row) => {
                const clearbitFName = row?.row?.original?.clearbitData?.givenName;
                const clearbitLName = row?.row?.original?.clearbitData?.familyName;
                const name = clearbitFName && clearbitLName ? buildInitialsForPicture('', clearbitFName, clearbitLName) : row?.row?.original?.firstName ? buildInitialsForPicture('', row?.row?.original?.firstName, '') : buildInitialsForPicture('', row?.row?.original?.firstName, '');

                return (
                    <Avatar className="logo-avatar-img" src={row?.row?.original?.clearbitData && row?.row?.original?.clearbitData?.companyLogo ? row?.row?.original?.clearbitData?.companyLogo : ''}>
                        {!row?.row?.original?.clearbitData?.companyLogo ? name : ''}
                    </Avatar>
                );
            },
            size: 40,
            maxSize: 40,
            header: '',
            enableSorting: false,
            id: 'companyLogo',
        }),
        columnHelper.accessor('name' as any, {
            cell: (row) => {
                return (
                    <div>
                        <p className="bolded-cellContent">{`${row.row.original.firstName} ${row.row.original?.lastName || ''}`}</p>
                        <p className="cellContent">{row.row.original?.email}</p>
                    </div>
                )
            },
            header: 'Name',
            size: 250,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'firstName'
        }),
        columnHelper.accessor('icp', {
            cell: (row: any) => {
                const icpChipColor = 'blue';

                return (
                    <div>
                        <CustomTooltip title={row?.getValue()?.length > 0 && row?.getValue()?.map((value: string) => value).join(', ')}>
                            <div>
                                {row?.getValue()?.length > 0 ? (
                                    row?.getValue()?.length > 1 ? (
                                        <div className="table-chip-cell">
                                            <MuiChip circleIcon chipColor={icpChipColor} label={row?.getValue()[0]} />
                                            <p className="table-chip-count">
                                                +{row?.getValue()?.length - 1}
                                            </p>
                                        </div>
                                    ) : (
                                        <MuiChip circleIcon chipColor={icpChipColor} label={row?.getValue()[0]} />
                                    )
                                ) : null}
                            </div>
                        </CustomTooltip>
                        {/* )} */}
                    </div>
                );
            },
            header: 'ICP',
            enableSorting: false,
            id: 'icp',
        }),
        columnHelper.accessor('buyerPersona', {
            cell: (row: any) => {
                const icpChipColor = 'red';

                return (
                    <div>
                        <CustomTooltip title={row?.getValue()?.length > 0 && row?.getValue()?.map((value: string) => value).join(', ')}>
                            <div>
                                {row?.getValue()?.length > 0 ? (
                                    row?.getValue()?.length > 1 ? (
                                        <div className="table-chip-cell">
                                            <MuiChip circleIcon chipColor={icpChipColor} label={row?.getValue()[0]} />
                                            <p className="table-chip-count">
                                                +{row?.getValue()?.length - 1}
                                            </p>
                                        </div>
                                    ) : (
                                        <MuiChip circleIcon chipColor={icpChipColor} label={row?.getValue()[0]} />
                                    )
                                ) : null}
                            </div>
                        </CustomTooltip>
                        {/* )} */}
                    </div>
                );
            },
            header: 'Buyer Persona',
            enableSorting: false,
            id: 'buyerPersona',
        }),
        columnHelper.accessor('status', {
            cell: (row) => {
                const statusOptions = () => {
                    const baseOptions = [
                        {
                            name: 'In Review',
                            value: EventRegistrantStatus.INVITE
                        },
                        {
                            name: 'Rejected',
                            value: EventRegistrantStatus.REJECTED
                        },
                        {
                            name: 'Confirmed',
                            value: EventRegistrantStatus.CONFIRMED
                        },
                        {
                            name: 'Invited',
                            value: EventRegistrantStatus.INVITED
                        }
                    ];
            
                    if (row.row.original?.ticket && row.row.original?.ticket?.type === TicketType.PAID) {
                        baseOptions.splice(1, 0, {
                            name: 'Approved',
                            value: EventRegistrantStatus.APPROVED
                        });
                    }
            
                    if (row.row.original?.ticket && row.row.original?.ticket?.enableWaitlistStatus === EnableTicketWaitlistStatus.ENABLE)
                    {
                        baseOptions.splice(3, 0, {
                            name: 'Waitlist',
                            value: EventRegistrantStatus.WAITLIST
                        });
                    }
            
                    return baseOptions;
                };

                const currentTimestamp = Math.floor(new Date().getTime() / 1000.0);
                const [selectedOption, setSelectedOption] = useState<any>(statusOptions().find((option) => option.value == row.row.original.status));

                const chipColor = () => {
                    switch (selectedOption?.value) { 
                        case EventRegistrantStatus.INVITE:
                            return 'grey';
                        case EventRegistrantStatus.APPROVED:
                            return 'green';
                        case EventRegistrantStatus.REJECTED:
                            return 'red';
                        case EventRegistrantStatus.DELETED:
                            return 'red';
                        case EventRegistrantStatus.CONFIRMED:
                            return 'blue';
                        case EventRegistrantStatus.WAITLIST:
                            return 'yellow';
                        case EventRegistrantStatus.INVITED:
                            return 'grey';
                        default:
                            return 'grey';
                    }
                };

                useEffect(() => {
                    setSelectedOption(statusOptions().find((option) => option.value == row.row.original.status));
                }, [row.row.original.status]);

                return (
                    <div>
                        {/* <ChipDropdown
                            disabled={currentTimestamp > Number(eventReduxData?.eventEndDateTime)}
                            label={selectedOption?.name}
                            disabledLabel={'You cannot change status once the event has ended'}
                            // options={statusOptions().filter((option) => option.value !== row.row.original.status)}
                            onClick={() => {
                                setShowRegistrantDetailsPopup(true);
                                setSelectedRegistrant(row.row.original);
                            }}
                            chipColor={chipColor()}
                        /> */}
                        <MuiChip 
                            chipColor={chipColor()}
                            label={selectedOption?.name}
                            circleIcon
                        />

                        {/* {
                            showRegistrantDetailsPopup && <PopupDialog modalTitle="" show={showRegistrantDetailsPopup} cancelClick={() => setShowRegistrantDetailsPopup(false)} modalContent={<RegistrantUpdateComponent registrantData={row.row.original} statusOptions={statusOptions()} />} />
                        } */}
                    </div>
                )
            },
            header: 'Status',
            size: 150,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'status'
        }),
        columnHelper.accessor('actions' as any, {
            cell: (row) => {
                return (
                    <TableActions 
                        actions={[
                            {
                                title: 'Edit', onClick: () => {
                                    handleMinimalViewRowClick(row.row.original);                                    
                                }
                            },
                            {
                                title: 'Delete', onClick: () => {
                                    onRowDelete(row.row.original);
                                }
                            }
                        ]}
                    />
                )
            },
            header: '',
            size: 40,
            enableSorting: false,
            id: 'actions'
        })
    ];

    const [allColumns, setAllColumns] = useState<any[]>(columns);

    const handleApplyFilters = (filters: { selectedColumn: string, inputValue: string }[]): void => {
        filters.forEach(filter => {
            searchParams.append(filter.selectedColumn, filter.inputValue); 
        });
        setCurrentUrl(`${currentpath}?${searchParams.toString()}`);
        navigate(`${currentpath}?${searchParams.toString()}`);

        // code to append new query along with existing query
        // const currentSearchParams = new URLSearchParams(currentUrl.split('?')[1]);
        // filters.forEach(filter => {
        //     currentSearchParams.append(filter.selectedColumn, filter.inputValue);
        // });
        // setCurrentUrl(`${currentpath}?${currentSearchParams.toString()}`);
        // navigate(`${currentpath}?${currentSearchParams.toString()}`);
    };

    const handleInvoiceDownload = async (): Promise<void> => 
    { 
        setInvoiceDownloadSpinner(true);
        try 
        {
            const response = await downloadInvoices(eventId);
            // Use file-saver to save the blob as a file
            saveAs(response.data, `${eventReduxData.title} invoices.zip`);
            toast.success('Invoices downloaded successfully');
        } 
        catch (error) 
        {
            console.error('Error downloading the file:', error);
            toast.error('Error in downloading invoices');
        }
        finally
        {
            setInvoiceDownloadSpinner(false);
        }
    };

    const handleBulkActions = async (moveToConfirmed?: boolean): Promise<void> =>
    {
        setBulkUpdateSpinner(true);

        const data = {
            registrantIds: selectedRowIds,
            status: moveToConfirmed ? EventRegistrantStatus.CONFIRMED : status as unknown as EventRegistrantStatus
        };

        try 
        {
            const isStatusUpdated = await bulkUpdateRegistrantStatus(eventId, data);
            if(isStatusUpdated)
            {
                setRefresh(true);
                setSelectedRowIds([]);
                toast.success('Registrants updated successfully');
            }
        } 
        catch (error) 
        {
            console.log(error);
        }
        finally
        {
            setBulkUpdateSpinner(false);
            setShowBulkActionsPopup(false);
        }
    };

    const handleDownloadCSV = async ():Promise<void> => 
    {
        setCsvConversionSpinner(true);
        try 
        {
            const response = await downloadRegistrantCSV(eventId);
            // Use file-saver to save the blob as a file
            saveAs(response.data, `${eventReduxData.title} registrants.csv`);
        }
        catch (error) 
        {
            console.log(error);
            toast.error('Error downloading the file');
        }
        finally
        {
            setCsvConversionSpinner(false);
        }
    };

    const handleMinimalViewRowClick = (row: EventRegistrant): void =>
    {
        setDrawerOpen(true);
        setSelectedRegistrant(row);
    };

    const handlePopupClose = (): void =>
    {
        setSelectedRegistrant(null);
        setDrawerOpen(false);
    };

    const removeRegistrantFromTable = async (): Promise<void> =>
    {
        try 
        {
            await deleteEventRegistrant(eventId, selectedRegistrant?.id as string)
            .then(() => 
            {
                setShowDeletePopup(false);
                setSelectedRegistrant(null);
                toast.success('Registrant removed successfully');
                setRefresh(true);
            })
            .catch((error) =>
            {
                console.log(error);
                toast.error((error as Error)?.message || 'Error in removing registrant');
            })
        } 
        catch (error) 
        {
            console.log(error);
        }
    };

    const fetchCounts = async (): Promise<void> => 
    {
        try 
        {
            const encodedParams = getEncodedParams(currentUrl);
            const count = await getEventRegistrantCount(Number(eventId), encodedParams);
            const allStatusCount = await getEventRegistrantStatusCount(Number(eventId));

            if(!totalRegistrantCount)
            {
                setTotalRegistrantCount(count);
            }
            if (count)
            {
                setRegistrantCount(count);
                setIsEmpty(count == 0 && encodedParams.toString().length === 0);
            }

            if (allStatusCount) {
                const regStatusEnumObject = _.mapValues(EventRegistrantStatus, (value) => value.toString());
                setRegistrantStatusCount(_.mapKeys(allStatusCount, (value, key) => regStatusEnumObject[key as unknown as number]));
            }
        } 
        catch (error) 
        {
            console.log(error);
        }
    };

    const fetchData = async (): Promise<void> => {
        try {
            const encodedParams = getEncodedParams(currentUrl);
            // Call API with the encoded parameters
            const registrantData = await getAllEventRegistrants(
                Number(eventId),
                pageSize ? pageSize : 25,
                currentPage - 1,
                ...encodedParams
            );
    
            if (registrantData) {
                setRows(registrantData);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setShowSpinner(false);
            setRefresh(false);
        }
    };
    
    const fetchEventTickets = async (): Promise<void> => {
        try {
            const eventTicketCount = await ticketsCount(eventId);
            if (eventTicketCount) {
                const eventTickets = await getAlleventTickets(eventTicketCount, 0, eventId);
                if (eventTickets) {
                    setTickets(eventTickets);
                    if (eventTickets?.length > 0 && eventTickets?.some((ticket) => ticket.type === TicketType.PAID && ticket.enableInvoice === EnableInvoiceStatus.ENABLE)) 
                    {
                        setIsEnableInvoiceDownload(true);
                    }
                }
            }
        }
        catch (error) {
            console.log(error);
        }
    };

    const fetchRegistrationFormData = async (): Promise<void> => 
    {
        if (eventReduxData?.registrationFormId) 
        {
            try 
            {
                const registrationForm = await getRegistrationFormById(eventReduxData.registrationFormId);
                if (registrationForm) 
                {
                    const formComponents = registrationForm?.formFields;
                    const csvColArray = [];
                    const dynamicComponents = formComponents && formComponents?.length > 0 ? formComponents?.map((component):{
                        column_name: string;
                        display_label: string;
                        info_hint: string;
                        type: string;
                        required: boolean;
                    } => 
                    {
                        const label = component?.propertyKey;
                        csvColArray.push(label);
                        return {
                            column_name: label as string,
                            display_label: label as string,
                            info_hint: component?.placeholder as string,
                            type: 'text',
                            required: component?.fieldMandatory == CustomFieldMandatory.MANDATORY
                        };
                    }) : [];
                    if (eventReduxData.registrationType === 2) 
                    {
                        // const eventTicketsCount = await ticketsCount(eventId);
                        // const eventTickets = await getAlleventTickets(eventTicketsCount, 0, eventId);

                        const colName = 'Ticket Name';
                        const ticketName = {
                            column_name: camelCase(colName),
                            display_label: colName,
                            info_hint: 'Enter the name of the ticket',
                            type: 'text',
                            required: true,
                            // validators: {
                            //     values: eventTickets.map((eventTicket) =>
                            //     {
                            //         return {
                            //             value: eventTicket.name,
                            //             display_label: eventTicket.name
                            //         }
                            //     }),
                            //     case_sensitive: false
                            // },
                        };
                        if (dynamicComponents)
                        {
                            dynamicComponents.push(ticketName);
                        }
                    }
                    setDynamicColumns([...dynamicComponents]);
                }

            }
            catch (error) 
            {
                console.log(error);
            }
            finally
            {
                setCsvBoxReady(true);
            }
        }
    };

    const fetchRegistrantGraphData = async (): Promise<void> => 
    {
        try 
        {
            const registrantGraphData = await getRegistrantsGraphData(eventId);

            if (import.meta.env.VITE_NODE_ENV === 'demo' && registrantGraphData?.trend && (!(_.isEmpty(registrantGraphData?.trend)))) 
            {
                setTrendData(registrantGraphData?.trend);
            }
        }
        catch (error) 
        {
            console.log(error);
        }
    };

    const renderNormalViewForEventRegistrants = (): React.JSX.Element => {
        return (
            <div id="eventRegistrantsPage">
                <div className="header-container">
                    <h2 className="heading">{'Registrants'}</h2>
                    <div className="buttons-container">
                        <CustomButton
                            name={chartVisibility ? 'Close Charts' : 'Open Charts'}
                            onClick={(): void => {
                                setChartVisibility(!chartVisibility);
                            }}
                            btnType='secondary'
                        />
                    </div>
                </div>

                {chartVisibility ? <EventRegistrantGraph eventId={eventId} registrantCount={totalRegistrantCount} /> : null}

                <div
                    style={{
                        height: chartVisibility ? '60%' : 'calc(100vh - 132px)',
                        maxHeight: !chartVisibility ? 'calc(100vh - 132px)' : '',
                        flex: 1
                    }}
                >
                    {
                        (isEmpty && Number(registrantCount) === 0) ? (
                            <TableEmptyComponent
                                infoText={'No Registrants Yet'}
                                emptyImg={registrantEmptyImage}
                            />
                        ) : (
                            <div className="h-100">
                                <TanstackToolbarWithServerFilter
                                    columns={columns}
                                    filterColumns={registrantFilterColumnOptions(tickets, registrantStatusCount, eventReduxData)}
                                    setColumns={setAllColumns}
                                    tabs={useRegistrantToolbarTabs({eventId, navigate, registrantCount:totalRegistrantCount, registrantStatusCount, setCurrentUrl, tickets, eventReduxData})}
                                    handleApplyFilters={handleApplyFilters}
                                    setCurrentUrl={setCurrentUrl}
                                    extraActionButtons={[
                                        ...(isEnableInvoiceDownload ? [
                                                <CustomButton 
                                                    name=""
                                                    btnType='secondary'
                                                    onClick={handleInvoiceDownload}
                                                    startIcon={<CustomTooltip title={<p>{'Download invoices'}</p>}><FontAwesomeIcon icon={['fal', 'file-invoice-dollar']} /></CustomTooltip>}
                                                    loading={invoiceDownloadSpinner}
                                                />
                                            ]: 
                                        []),
                                        <CustomTooltip title="Import CSV">
                                            <div>
                                                <DataImport iconButton={true} licenseKey={import.meta.env.VITE_REGISTRANT_SHEET_ID} eventId={eventId} />
                                            </div>
                                        </CustomTooltip>,
                                        <CustomButton 
                                            key="downloadCSV"
                                            loading={csvConversionSpinner} 
                                            onClick={handleDownloadCSV} 
                                            name='' 
                                            btnType='secondary' 
                                            startIcon={<CustomTooltip title="Download as CSV"><FontAwesomeIcon icon={['fal', 'arrow-down-to-bracket']} /></CustomTooltip>} 
                                        />,
                                        ...(selectedRowIds?.length > 0 && status ? [
                                            <CustomButton
                                                key="bulkActions"
                                                loading={bulkUpdateSpinner}
                                                name={'Actions'}
                                                type='button'
                                                btnType='primary'
                                                onClick={() => setShowBulkActionsPopup(true)}
                                            />
                                        ] : [])
                                    ]}
                                />
                                <TanstackTable
                                    data={rows}
                                    initialColumns={allColumns}
                                    rowCount={registrantCount}
                                    showNoRowsOverlay={registrantCount == 0}
                                    pageSize={pageSize}
                                    currentPage={currentPage - 1}
                                    updateCurrentPage={updateCurrentPage as any}
                                    updatePageSize={updatePageSize}
                                    onRowClick={(row) => openDrawer(row as EventRegistrant)}
                                    showSpinner={showSpinner}
                                    height={chartVisibility ? `calc(100% - 124px)` : `calc(100% - 118px)`}
                                    checkboxSelection
                                    selectedRows={selectedRowIds}
                                    setSelectedRows={setSelectedRowIds}
                                    rightPinnedColumns={['actions']}
                                    leftPinnedColumns={['companyLogo']}
                                />
                            </div>
                        )
                    }
                </div>

                {
                    showBulkActionsPopup && 
                    <DeletePopup
                        show={showBulkActionsPopup}
                        modalTitle='Actions'
                        modalContent={`Choose an action to perform on ${selectedRowIds?.length} registrants`}
                        cancelClick={(): void => setShowBulkActionsPopup(false)}
                        extraActions={[
                            <CustomButton width="100%" style={{ width: "100%" }} btnType='secondary' name='Retrigger Email' onClick={() => handleBulkActions() } disabled={bulkUpdateSpinner} />,
                            !(status && Number(status) === EventRegistrantStatus.CONFIRMED) && <CustomButton width="100%" style={{ width: "100%" }} btnType='primary' name='Move to confirmed' onClick={() => handleBulkActions(true) } disabled={bulkUpdateSpinner} />,
                        ].filter(Boolean)}
                    />
                }

            </div>
        )
    };

    const renderMinimalViewForEventRegistrants = (): React.JSX.Element => { 
        return (
            <div id="eventRegistrantsPageMinimalView">
                <MinimalViewComponent
                    title="Registrants"
                    description="Manage your Guests and their account permissions here."
                    titleBarActions={[
                        <CustomButton
                            key={1}
                            name={chartVisibility ? 'Close Charts' : 'Open Charts'}
                            onClick={(): void => {
                                setChartVisibility(!chartVisibility);
                            }}
                            btnType='secondary'
                        />,
                    ]}
                    showCharts={chartVisibility}
                    trendData={[{
                        title: 'Total Registrants',
                        value: `${Number(totalRegistrantCount) !== 0 ? totalRegistrantCount : '-'}`
                    },
                    {
                        title: 'Customers',
                        value: trendData?.customer && trendData?.customer !== '' ? trendData?.customer : '-',
                    },
                    {
                        title: 'Prospects',
                        value: trendData?.prospect && trendData?.prospect !== '' ? trendData?.prospect : '-',
                    },
                    {
                        title: 'Prospects in ICP',
                        value: trendData?.icp && trendData?.icp !== '' ? trendData?.icp : '-',
                    }
                    ]}
                    tabData={useRegistrantToolbarTabs({eventId, navigate, registrantCount:totalRegistrantCount, registrantStatusCount, setCurrentUrl, tickets, eventReduxData})}
                    actions={[
                        isEnableInvoiceDownload && 
                        <CustomButton
                            key={2} 
                            name=""
                            btnType='secondary'
                            onClick={handleInvoiceDownload}
                            startIcon={<CustomTooltip title={<p>{'Download invoices'}</p>}><FontAwesomeIcon icon={['fal', 'file-invoice-dollar']} /></CustomTooltip>}
                            loading={invoiceDownloadSpinner}
                        />,
                        <CustomTooltip title="Import CSV">
                            <div>
                                <DataImport iconButton={true} licenseKey={import.meta.env.VITE_REGISTRANT_SHEET_ID} eventId={eventId} />
                            </div>
                        </CustomTooltip>,
                        <CustomTooltip title="Export CSV">
                            <div>
                                <CustomButton loading={csvConversionSpinner} onClick={handleDownloadCSV} name='' btnType='secondary' startIcon={<FontAwesomeIcon icon={['fal', 'arrow-down-to-bracket']} />} />
                            </div>
                        </CustomTooltip>,
                            !isEmpty && <TanstackToolbarWithServerFilter
                            key={1}
                            columns={minimalColumns}
                            filterColumns={registrantFilterColumnOptions(tickets, registrantStatusCount, eventReduxData)}
                            handleApplyFilters={handleApplyFilters}
                            setCurrentUrl={setCurrentUrl}
                            minimalView={true}
                        />,
                        !isEmpty && <ColumnVisibilityDropdown 
                            key={2}
                            columns={minimalColumns}
                            setColumns={setAllColumns}
                            showIconButton={true}
                        />,
                    ]}
                    component={
                        <div>
                            {
                                isEmpty ?
                                    <div style={{ height: 'calc(100vh - 340px)' }}>
                                        <TableEmptyComponent
                                            emptyImg={''}
                                            infoText={'No Registrant'}
                                        />
                                    </div>
                                    :
                                    <TablePopup 
                                        rows={rows}
                                        columns={drawerOpen ? minimalColumns?.filter((item, index) => index < 2) : allColumns}
                                        count={registrantCount}
                                        pageSize={pageSize}
                                        currentPage={currentPage - 1}
                                        updatePageSize={updatePageSize}
                                        updateCurrentPage={updateCurrentPage as any}
                                        height={chartVisibility ? 'calc(100vh - 396px)' : 'calc(100vh - 278px)'}
                                        drawerOpen={drawerOpen}
                                        onRowClick={(row) => handleMinimalViewRowClick(row)}
                                        popupContent={
                                            <TablePopupDetails 
                                                onPopupClose={handlePopupClose}
                                                title={`${selectedRegistrant?.firstName} ${selectedRegistrant?.lastName || ''}`}
                                                subTitle={`${selectedRegistrant?.clearbitData ? selectedRegistrant?.clearbitData?.company : ''}`}
                                                email={`${selectedRegistrant?.email}`}
                                                imageSrc={`${selectedRegistrant?.clearbitData ? selectedRegistrant?.clearbitData?.companyLogo : ''}`}
                                                altImage={`${buildInitialsForPicture(selectedRegistrant?.email, selectedRegistrant?.firstName, selectedRegistrant?.lastName || '')}`}
                                                icp={selectedRegistrant?.icp}
                                                buyerPersona={selectedRegistrant?.buyerPersona}
                                                tabData={tablePopupTabData}
                                            />
                                        }
                                    />
                            }
                        </div>
                    }
                    componentType='table'
                />
            </div>
        );
    };

    useEffect(() => {
        fetchCounts();
        fetchEventTickets();

        if (userDetails?.viewType === UserViewType.MINIMAL)
        {
            fetchRegistrantGraphData();
        }
    }, [currentUrl]);

    useEffect(() => {
        if (pageSize > 0 && currentPage)
        {
            fetchData();
        }
    }, [currentUrl, pageSize, currentPage, registrantCount]);

    useEffect(() => {
        if (userDetails?.viewType === UserViewType.MINIMAL)
        {
            setAllColumns(minimalColumns);
        }
        else
        {
            setAllColumns(columns);
        }
    }, [columns, userDetails]);

    useEffect(() => {
        fetchRegistrationFormData();
    }, [eventReduxData?.registrationFormId]);

    useEffect(() => {
        if (refresh) {
            fetchData();
            fetchCounts();
        }
        setRefresh(false);
    }, [refresh]);

    useMemo(() => {
        if (refreshCounts)
        {
            fetchCounts();
            setRefreshCounts(false);
        }
    }, [refreshCounts]);

    return (
        <>
            {
                userDetails?.viewType === UserViewType.NORMAL && renderNormalViewForEventRegistrants()
            }

            {
                userDetails?.viewType === UserViewType.MINIMAL && renderMinimalViewForEventRegistrants()
            }

            {
                showRegistrantDetailsPopup && <PopupDialog modalTitle="" show={showRegistrantDetailsPopup} cancelClick={() => setShowRegistrantDetailsPopup(false)} modalContent={<RegistrantUpdateComponent setRefreshCounts={setRefreshCounts} onClose={() => setShowRegistrantDetailsPopup(false)} setRegistrants={setRows} registrantData={selectedRegistrant as EventRegistrant} />} />
            }

            {
                showDeletePopup &&
                <DeletePopup
                    acceptBtn='Delete'
                    acceptClick={() => removeRegistrantFromTable()}
                    cancelClick={() => {
                        setShowDeletePopup(false);
                        setSelectedRegistrant(null);
                    }}
                    modalContent={
                        <p className="content">
                            Are you sure you want to remove {selectedRegistrant?.firstName} from this event?
                            <br />
                            Note: Registrant has to register again.
                        </p>
                    }
                    modalTitle='Delete Registrant'
                    show={showDeletePopup}
                    rejectBtn='Cancel'
                    modalHeaderIcon='trash'
                />
            }
        </>
    )
};

export default EventRegistrantsPage;