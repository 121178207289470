import { Avatar, Box, Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import eventBus from '../../scripts/event-bus';
import APP_CONSTANTS from '../../scripts/constants';
import Vendors from '../../components/Vendors/Vendors';  
import { useDispatch } from 'react-redux';
import { resetVendors } from '../../redux/vendors/Vendors';
import { CONTENT } from '../../scripts/i18n';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DataImport from '../../common/DataImport';
import { VendorsContext } from '../../contexts/VendorsPageContext';
import { getVendorById } from '../../scripts/apis/vendors';
import TanstackTable from '../../common/TanstackTable/TanstackTable';
import { createColumnHelper } from '@tanstack/react-table';
import { Vendor } from './interfaces';
import { TableActions } from '../../common/TableActions';
import TableEmptyComponent from '../../common/TableEmptyComponent';
import vendorEmptyImg from '../../assets/icons/empty-state/vendorEmpty.svg';
import { IVendors } from '../../components/Vendors/IVendors';
import { CustomButton } from '../../common/FormComponents/Buttons';
import DeletePopup from '../../common/DeletePopup';
import HeaderBar from '../../common/Headerbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddVendors from '../Events/EventDetails/People/EventVendors/AddVendors';
import toast from 'react-hot-toast';
import CardComponent from '../../components/Events/Registrations/CardComponent';
import EventsCardHeaderComponent from '../../components/Events/EventComponents/EventsCardHeaderComponent';
import TanstackToolbarWithServerFilter from '../../common/TanstackTable/TanstackToolbarWithServerFilter';
import { UserContext } from '../../contexts/UserContext';
import { UserViewType } from '../Settings/enum/users.enum';
import { MinimalViewComponent } from '../../common/MinimalView/MinimalViewComponent';
import CustomSpinner from '../../common/CustomSpinner';
import ColumnVisibilityDropdown from '../../common/TanstackTable/ColumnVisibilityDropdown';
import CustomTooltip from '../../common/Tooltip';

import mailIcon from '../../assets/icons/envelope-light.svg';
import twitterIcon from '../../assets/icons/twitter-icon.svg';
import linkedInIcon from '../../assets/icons/linkedin-solid.svg';

import './styles.scss';

const VendorsPage: React.FC<{ eventId?: string | number }> = (props):React.JSX.Element =>
{

    const { vendorId } = useParams();
    const { eventId } = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentpath = useLocation().pathname;

    const searchParams = new URLSearchParams();

    const {
        userDetails
    } = useContext(UserContext);

    const { 
        rows, 
        vendorsDataCount, 
        isEmpty, 
        vendors, 
        setRefresh, 
        deleteVendorsFromTable, 
        pageSize, 
        currentPage, 
        updateCurrentPage, 
        updatePageSize, 
        showSpinner, 
        showDeletePopup,
        setSelectedVendor,
        setShowDeletePopup,
        selectedVendor,
        cardView,
        isTableView,
        setCurrentUrl
    } = useContext(VendorsContext);

    const handleEdit = (row: IVendors, routeFromId?: boolean):void => 
    {
        dispatch(resetVendors());
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: CONTENT.VENDORS.SIDE_DRAWER.HEADING.EDIT,
            hideCloseButton: true,
            component: <Vendors createorUpdateGlobalVendor={true} vendor-data={row} is-editable={true} setRefresh={setRefresh} routeFromId={routeFromId} />
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });
    };

    const openDrawer = (createComp = false):void => 
    {
        if (createComp) 
        {
            dispatch(resetVendors());
            eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
                heading: CONTENT.VENDORS.SIDE_DRAWER.HEADING.CREATE,
                hideCloseButton: true,
                // buttonName: CONTENT.EVENTS_PAGE.SIDE_DRAWER_CONTENT.CREATE.BUTTON,
                component: <Vendors createorUpdateGlobalVendor={true} setRefresh={setRefresh} />
            });
        }
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });
    };

    const createVendor = ():void => 
    {
        dispatch(resetVendors());
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: 'Create Vendor',
            event: 'create-vendor',
            component: <Vendors createEventVendor={true} data-eventId={eventId} eventVendor={true} setRefresh={setRefresh} />,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });
    };

    const addVendor = ():void => 
    {
        dispatch(resetVendors());
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: 'Add Vendor',
            event: 'add-vendor',
            component: <AddVendors isTableView={isTableView && userDetails?.viewType === UserViewType.NORMAL} data-id={eventId} setRefresh={setRefresh} />,
        });
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });
    };

    const editVendor = (vendorInfo: Vendor, routeFromEventVendor?: boolean):void => 
    {
        dispatch(resetVendors());
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: CONTENT.VENDORS.SIDE_DRAWER.HEADING.EDIT,
            component: <Vendors createEventVendor={true} vendor-data={vendorInfo} is-editable={true} setRefresh={setRefresh} routeFromEventVendor={routeFromEventVendor} data-eventId={eventId} />
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });
    };

    const handleApplyFilters = (filters: { selectedColumn: string, inputValue: string }[]): void => {
        filters.forEach(filter => {
            searchParams.append(filter.selectedColumn, filter.inputValue);
        });
        setCurrentUrl(`${currentpath}?${searchParams.toString()}`);
        navigate(`${currentpath}?${searchParams.toString()}`);
    };

    useEffect(():void => 
    {
        const fetchDataFromRoute = async ():Promise<void> => 
        {
            if (vendors && vendors.length && vendorId) 
            {
                try 
                {
                    const vendorDataById = await getVendorById(Number(vendorId));
                    if (vendorDataById) 
                    {
                        handleEdit(vendorDataById as IVendors, true);
                    }
                    else if (!vendorDataById) 
                    {
                        toast.error('The speaker you\'re trying to access doesn\'t exist');
                        navigate('/vendors');
                    }
                }
                catch (error) 
                {
                    console.log(error);
                }
            }
        };
        fetchDataFromRoute();
    }, [vendors]);

    useEffect((): void => 
    {
        if(!pageSize)
        {
            updatePageSize(25);
        }
    }, [pageSize]);  

    const columnHelper = createColumnHelper<Vendor>();

    const vendorColumns = [
        columnHelper.accessor('name', {
            cell: (row) => {
                return (
                    <div>
                        <p className="bolded-cellContent">{row.getValue()}</p>
                        {userDetails?.viewType === UserViewType.MINIMAL && <p className="cellContent">{row.row.original.email}</p>}
                    </div>
                )
            },
            header: 'Name',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'name'
        }),
        ...(userDetails?.viewType === UserViewType.NORMAL ? [columnHelper.accessor('email', {
            cell: (row) => {
                return (<p className="cellContent">{row.getValue()}</p>)
            },
            header: 'Email',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'email'
        })] : []),
        columnHelper.accessor('vendorName', {
            cell: (row) => {
                return (<p className="cellContent">{row.getValue()}</p>)
            },
            header: 'Contact Person',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'vendorName'
        }),
        columnHelper.accessor('description', {
            cell: (row) => {
                return (<p className="cellContent">{row.getValue()}</p>)
            },
            header: 'Description',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'description'
        }),
        columnHelper.accessor('social.linkedIn', {
            cell: (row) => {
                return (<p className="cellContent">{row.getValue()}</p>)
            },
            header: 'Website/Social Media Link',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'social.linkedIn'
        }),
        columnHelper.accessor('actions', {
            cell: (row) => {
                return (
                    <TableActions 
                        actions={[
                        {
                            title: 'Edit',
                            onClick: (): void => 
                            {
                                handleEdit(row?.row?.original as IVendors);
                            }
                        }, 
                        {
                            title: 'Delete',
                            onClick: (): void => 
                            {
                                setSelectedVendor(row?.row?.original as IVendors);
                                setShowDeletePopup(true);
                            }
                        }]}
                    />
                );
            },
            header: '',
            size: 40,
            enableSorting: false,
            id: 'actions'
        })
    ];

    const [allColumns, setAllColumns] = useState(vendorColumns);

    const filterColumns = [
        {
            header: 'Name',
            id: 'name',
            meta: {
                type: 'string'
            }
        },
        {
            header: 'Email',
            id: 'email',
            meta: {
                type: 'string'
            }
        },
        {
            header: 'Vendor Name',
            id: 'vendorName',
            meta: {
                type: 'string'
            }
        },
    ];

    const renderCardViewForVendorsPage = (): React.JSX.Element => {
        return (
            <div id="eventVendorsPage">
                <div className="vendors-container">
                    <EventsCardHeaderComponent heading='Vendors' count={vendorsDataCount} buttonAction={() => navigate('/events/' + eventId + '/people/vendors')} />

                    <Grid container spacing={2}>
                        {
                            vendors?.length > 0 && vendors?.map((vendor: Vendor, index: number) => {

                                const vendorContact = (<div className="vendor-contact-container"><p>{vendor?.vendorName}</p><FontAwesomeIcon icon={['fal', 'envelope']} /></div>);
                                const vendorSocialsArr: {icon: string, link: string}[] = [];
                                const vendorImage = (<div><Avatar className="card-empty-vendor-avatar" src={vendor?.name?.charAt(0)}><p>{vendor?.name?.charAt(0)?.toUpperCase()}</p></Avatar></div>);

                                vendorSocialsArr?.unshift({
                                    icon: mailIcon,
                                    link: vendor?.email
                                });

                                if(vendor?.social?.twitter)
                                {
                                    vendorSocialsArr.push({
                                        icon: twitterIcon,
                                        link: vendor?.social?.twitter
                                    });
                                }
                                if(vendor?.social?.linkedIn)
                                {
                                    vendorSocialsArr.push({
                                        icon: linkedInIcon,
                                        link: vendor?.social?.linkedIn
                                    });
                                }

                                const vendorName = (<div className="vendor-name-container"><p>{vendor?.name}</p></div>);

                                const sponsorSocials = (<div className="vendor-socials-icons">{vendorSocialsArr.map((social, index) => { return <Box component={'img'} key={index} src={social.icon} height={'1rem'} width={'1rem'} onClick={(event) => 
                                    {
                                        if(social.link !== vendor?.email)
                                        {
                                            window.open(social.link, '_blank');
                                            event.stopPropagation();
                                        }
                                        else
                                        {
                                            navigator.clipboard.writeText(`${vendor.email}`);
                                            toast.success('Email copied to clipboard');
                                            event.stopPropagation();
                                        }
                                    }} 
                                    /> 
                                })}</div>);

                                const footerContent = (
                                    <div className="vendor-social-contents">
                                        {sponsorSocials}
                                    </div>
                                );

                                return (
                                    <Grid key={index} item xl={4} lg={4} md={6} sm={12} xs={12}>
                                        <CardComponent 
                                            cardMinHeight='124px'
                                            header={vendorImage}
                                            contentHeading={vendorName}
                                            headerBold
                                            gapNotRequired
                                            footerLeft={footerContent}
                                            onClick={() => editVendor(vendor)}
                                        />
                                    </Grid>
                                )
                            })

                        }

                        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                            <CardComponent 
                                emptyText='+ Add Vendors'
                                emptyCardHeight='92px'
                                onClick={addVendor}
                            />
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    };

    const renderNormalViewForVendorsPage = (): React.JSX.Element => { 
        return (
            <div style={{ height: (isTableView && eventId) ? 'calc(100vh - 57px)' : '', overflow: (isTableView && eventId) ? 'hidden' : '' }} id={eventId ? "eventVendorsPage" : "vendorsPage"}>
                <HeaderBar
                    title={eventId ? <p><FontAwesomeIcon onClick={() => navigate('/events/' + eventId + '/overview')} cursor="pointer" style={{ marginRight: '8px' }} icon={['fal', 'arrow-left']} /> Vendors</p> : <p>Vendors</p>}
                    buttons={
                        eventId ?
                            [
                                <DataImport licenseKey={import.meta.env.VITE_VENDOR_SHEET_ID} eventId={eventId} />,
                                <CustomButton btnType='secondary' name='Select Vendor' onClick={addVendor} />,
                                <CustomButton btnType='secondary' name='Add Vendor' onClick={createVendor}/>
                            ] :
                            [
                                <DataImport licenseKey={import.meta.env.VITE_VENDOR_SHEET_ID} eventId={eventId} />,
                                <CustomButton name='Add Vendor' btnType='primary' onClick={() => openDrawer(true)} />
                            ]
                    }
                />
                <div style={{
                    height:'100%'
                }}>
                    {
                        isEmpty ?
                            <TableEmptyComponent emptyImg={vendorEmptyImg} openDrawer={openDrawer} infoText={'No Vendor'} subInfoText={'Add your first Vendor'} />
                            :
                            <div className="h-100">
                                {/* <VendorsPageToolbar 
                                    count={vendorsDataCount}
                                    rows={vendors}
                                    setRows={setRows}
                                    columns={vendorColumns}
                                    setColumns={setAllColumns}
                                /> */}
                                <TanstackToolbarWithServerFilter 
                                    columns={vendorColumns}
                                    setColumns={setAllColumns}
                                    tabs={[{
                                        tabName: 'All',
                                        count: vendorsDataCount,
                                        selectedTab: true,
                                        navigation: () => {}
                                    }]}
                                    setCurrentUrl={setCurrentUrl as any}
                                    filterColumns={filterColumns}
                                    handleApplyFilters={(filters) => handleApplyFilters(filters)}
                                    extraActionButtons={[
                                        <CustomTooltip key={1} title="Import CSV">
                                            <div>
                                                <DataImport iconButton={true} licenseKey={import.meta.env.VITE_VENDOR_SHEET_ID} eventId={eventId ? eventId : null} />
                                            </div>
                                        </CustomTooltip>,
                                    ]}
                                />
                                <TanstackTable 
                                    initialColumns={allColumns}
                                    data={rows}
                                    rowCount={vendorsDataCount}
                                    pageSize={pageSize}
                                    currentPage={currentPage - 1}
                                    updateCurrentPage={updateCurrentPage as any}
                                    updatePageSize={updatePageSize}
                                    showSpinner={showSpinner}
                                    onRowClick={(row) => handleEdit(row)}
                                    rightPinnedColumns={['actions']}
                                    height={eventId ? 'calc(100% - 184px)' : 'calc(100% - 128px)'}
                                    showNoRowsOverlay={vendorsDataCount==0}
                                />
                            </div>
                    }
                </div>
            </div>
        )
    };

    const renderMinimalViewForVendorsPage = (): React.JSX.Element => { 
        return (
            <div style={{ height: eventId ? 'calc(100vh - 72px)' : '', maxWidth: eventId && userDetails?.viewType === UserViewType.MINIMAL ? 'calc(100vw - 187px)' : 'calc(100vw - 180px)' }} id={eventId ? "eventVendorsPage" : "vendorsPageMinimalView"}>
                <MinimalViewComponent
                    headerActions={[
                        eventId && <CustomButton key={1} name="Back" startIcon={<FontAwesomeIcon icon={['fal', 'arrow-left']} />} btnType='tertiary' onClick={() => navigate('/events/' + eventId + '/overview')} />,
                    ]}
                    title="Vendors"
                    description="Manage your Vendors and their account permissions here."
                    tabData={[
                        {
                            tabName: 'All',
                            count: vendorsDataCount,
                            navigation: () => { },
                            selectedTab: true
                        }
                    ]}
                    actions={[
                        !isEmpty && <TanstackToolbarWithServerFilter
                            key={1}
                            columns={vendorColumns}
                            filterColumns={filterColumns}
                            handleApplyFilters={handleApplyFilters}
                            setCurrentUrl={setCurrentUrl}
                            minimalView={true}
                        />,
                        !isEmpty && <ColumnVisibilityDropdown 
                            key={2}
                            columns={vendorColumns}
                            setColumns={setAllColumns}
                            showIconButton={true}
                        />,
                        <CustomTooltip key={3} title="Import CSV">
                            <div>
                                <DataImport iconButton={true} licenseKey={import.meta.env.VITE_VENDOR_SHEET_ID} eventId={eventId ? eventId : null} />
                            </div>
                        </CustomTooltip>,
                        <CustomButton
                            key={2}
                            name={<FontAwesomeIcon icon={['fal', 'plus']} />}
                            btnType='primary'
                            onClick={() => {
                                if (eventId) {
                                    addVendor()
                                }
                                else {
                                    createVendor()
                                }
                            }}
                        />
                    ]}
                    component={
                        <div className="minimalView-table-container">
                            {
                                isEmpty ?
                                    <TableEmptyComponent
                                        emptyImg={vendorEmptyImg}
                                        openDrawer={openDrawer}
                                        infoText={'No Vendor'}
                                        subInfoText={'Add your first Vendor'}
                                    />
                                    :
                                    <TanstackTable
                                        data={vendors}
                                        initialColumns={allColumns}
                                        rowCount={vendorsDataCount}
                                        pageSize={pageSize}
                                        currentPage={currentPage - 1}
                                        updateCurrentPage={updateCurrentPage as any}
                                        updatePageSize={updatePageSize}
                                        showSpinner={false}
                                        height={
                                            eventId ? 'calc(100vh - 380px)' : 'calc(100vh - 280px)' 
                                        }
                                        rightPinnedColumns={['actions']}
                                        onRowClick={(row) => handleEdit(row)}
                                        showNoRowsOverlay={vendorsDataCount==0}
                                    />
                            }
                        </div>
                    }
                    componentType='table'
                />
            </div>
        )
    };

    return (
        <>
            
            {
                (isTableView || !cardView) && userDetails?.viewType === UserViewType.NORMAL && renderNormalViewForVendorsPage()
            }

            {
                (!isTableView && cardView) && renderCardViewForVendorsPage()
            }

            {
                showSpinner ? <CustomSpinner width="100%" height="100%" /> : (userDetails?.viewType === UserViewType.MINIMAL && (isTableView || !cardView)) && renderMinimalViewForVendorsPage()
            }

            {
                showDeletePopup &&
                    <DeletePopup 
                        acceptBtn='Delete' 
                        acceptClick={() => deleteVendorsFromTable(selectedVendor as IVendors)} 
                        cancelClick={() => { 
                            setShowDeletePopup(false);
                            setSelectedVendor(null);
                        }} 
                        modalContent={`Are you sure you want to delete ${selectedVendor?.name}?`}
                        modalTitle='Delete Vendor'
                        show={showDeletePopup}
                        rejectBtn='Cancel'
                        modalHeaderIcon='trash'
                    />
            }
        </>
    );
};

export default VendorsPage;