export enum ActionType {
    CREATED = 1,
    UPDATED = 2,
    DELETED = 3
}

export enum FynoResponseStatus
    {
    FAILED = 'failed',
    DELIVERED = 'delivered',
}